import CryptoJS from "crypto-js";

export function GUID(): string {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function AESEncrypt(toEncrypt: string, key: string) {
  return CryptoJS.AES.encrypt(toEncrypt, key);
}

export function SetElemToWindowName(elemName: string, elemvalue: string) {
  const elemFullName = "#" + elemName + ":";
  const startingElemPos = window.name.indexOf(elemFullName);

  if (startingElemPos >= 0) {
    // Element is in string, find and replace value
    const endingElemPos = window.name.indexOf(
      "#",
      startingElemPos + elemFullName.length
    );
    const stringBefore = window.name.substring(0, startingElemPos);
    const stringAfter = window.name.substring(
      endingElemPos,
      window.name.length
    );

    window.name = stringBefore + elemFullName + elemvalue + stringAfter;
  } else {
    // Element is not in string - sipmplu add to end
    window.name += elemFullName + elemvalue;
  }
}

export function GetElemFromWindowName(elemName: string): string {
  const elemFullName = "#" + elemName + ":";
  const startingElemPos = window.name.indexOf(elemFullName);
  if (startingElemPos < 0) return "";

  let endingElemPos = window.name.indexOf(
    "#",
    startingElemPos + elemFullName.length
  );

  if (endingElemPos < 0) endingElemPos = window.name.length;

  return window.name.substring(
    startingElemPos + elemFullName.length,
    endingElemPos
  );
}

export async function GetAppConfig() {
  const configFile = "config.json";

  const configFileHeaders = new Headers();

  configFileHeaders.append("pragma", "no-cache");
  configFileHeaders.append("cache-control", "no-cache");

  const configFileInit = {
    method: "GET",
    headers: configFileHeaders,
  };

  const configFileRequest = new Request(process.env.BASE_URL + configFile);

  const runtimeConfig = await fetch(configFileRequest, configFileInit);

  return await runtimeConfig.json();
}
