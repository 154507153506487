import { createStore } from "vuex";
import application from "@/store/modules/application";
import logger from "@/store/modules/logger";

const store = createStore({
  state: {
    cfgServiceUrl: "",
    devCfgServiceUrl: "",
    useDevCfgService: sessionStorage.getItem("useDevCfgService")
      ? sessionStorage.getItem("useDevCfgService")
      : false,
    ssnLocalUId: null,
    appResetPwdToken: sessionStorage.getItem("appResetPwdToken"),
    appResetPwdUId: sessionStorage.getItem("appResetPwdUId"),
    appFinalMsg: null,
    appErrorMsg: sessionStorage.getItem("appErrorMsg"),
    deviceUId: localStorage.getItem("deviceUId"),
    appVersion: require("@/../package.json").appVersion,
    appProductUId: require("@/../package.json").apAppProductUId,
    authLogonToken: "",
  },
  modules: {
    application,
    logger,
  },

  getters: {
    getDeviceUId: (state: any) => {
      return state.deviceUId;
    },
    getAppResetPwdUId: (state) => {
      return state.appResetPwdUId;
    },
    getAppResetPwdToken: (state) => {
      return state.appResetPwdToken;
    },
    getAppVersion: (state) => {
      return state.appVersion;
    },
    getAppProductUId: (state) => {
      return state.appProductUId;
    },
    getCfgServiceUrl: (state) => {
      return state.cfgServiceUrl;
    },
    getDevCfgServiceUrl: (state) => {
      return state.devCfgServiceUrl;
    },
    getUseDevCfgService: (state) => {
      return state.useDevCfgService;
    },
    getAppErrorMsg: (state) => {
      return state.appErrorMsg;
    },
  },

  mutations: {
    setDeviceUId(state, value) {
      state.deviceUId = value;
      if (value) {
        localStorage.setItem("deviceUId", value);
      } else {
        localStorage.removeItem("deviceUId");
      }
    },
    setAppResetPwdUId(state, value) {
      state.appResetPwdUId = value;
      if (value) {
        sessionStorage.setItem("appResetPwdUId", value);
      } else {
        sessionStorage.removeItem("appResetPwdUId");
      }
    },
    setAppResetPwdToken(state, value) {
      state.appResetPwdToken = value;
      if (value) {
        sessionStorage.setItem("appResetPwdToken", value);
      } else {
        sessionStorage.removeItem("appResetPwdToken");
      }
    },
    setCfgServiceUrl(state, value) {
      state.cfgServiceUrl = value;
      /*
      if (value) {
        sessionStorage.setItem("cfgServiceUrl", value);
      } else {
        sessionStorage.removeItem("cfgServiceUrl");
      }
      */
    },
    setDevCfgServiceUrl(state, value) {
      state.devCfgServiceUrl = value;
      /*
      if (value) {
        sessionStorage.setItem("devCfgServiceUrl", value);
      } else {
        sessionStorage.removeItem("devCfgServiceUrl");
      }
        */
    },
    setUseDevCfgService(state, value) {
      state.useDevCfgService = value;
      sessionStorage.setItem("useDevCfgService", value);
    },
    setAppErrorMsg(state, value) {
      state.appErrorMsg = value;
      if (value) {
        sessionStorage.setItem("appErrorMsg", value);
      } else {
        sessionStorage.removeItem("appErrorMsg");
      }
    },
  },
});
export default store;
