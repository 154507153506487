import Axios from "axios";
import Store from "@/store";
import Notify from "devextreme/ui/notify";

import { IHttpBaseResponse } from "@/core/interface/IHttpBaseResponse";
import { BaseResultStatusEnum } from "@/core/enum/BaseResultStatusEnum";

export default {
  async get(epMethod: string): Promise<IHttpBaseResponse> {
    const result = await Axios.get(
      Store.getters["getUseDevCfgService"] === true
        ? Store.getters["getDevCfgServiceUrl"] + epMethod
        : Store.getters["getCfgServiceUrl"] + epMethod
    )
      .then((result) => {
        return this.processResultOK(result);
      })
      .catch((error) => {
        return this.processResultErr(error);
      });
    return result;
  },

  async post(epMethod: string, request: string): Promise<IHttpBaseResponse> {
    const headers = {
      "Content-Type": "application/json",
    };

    const result = await Axios.post(
      Store.getters["getUseDevCfgService"] === true
        ? Store.getters["getDevCfgServiceUrl"] + epMethod
        : Store.getters["getCfgServiceUrl"] + epMethod,
      request,
      {
        headers: headers,
      }
    )
      .then((result) => {
        return this.processResultOK(result);
      })
      .catch((error) => {
        return this.processResultErr(error);
      });
    return result;
  },

  processResultOK(result: any): Promise<IHttpBaseResponse> {
    if (result.data.err) {
      return Promise.resolve({
        isOk: false,
        data: null,
        message: result.data.err.msg,
        messageNumber: result.data.err.number,
        state: BaseResultStatusEnum.Error,
      });
    }
    return Promise.resolve({
      isOk: true,
      data: result.data,
      message: null,
      messageNumber: 0,
      state: BaseResultStatusEnum.OK,
    });
  },

  processResultErr(error: any): Promise<IHttpBaseResponse> {
    if (error.response === undefined) {
      return Promise.resolve(<IHttpBaseResponse>{
        isOk: false,
        data: null,
        message: "Server je nedostupný",
        messageNumber: -1,
        state: BaseResultStatusEnum.Unreachable,
      });
    }

    let stateStr = BaseResultStatusEnum.Unknown;
    let msgNumber = -1;

    switch (error.response.request.status) {
      case 401:
        stateStr = BaseResultStatusEnum.Logout;
        msgNumber = -3;
        break;
      default:
        stateStr = BaseResultStatusEnum.Error;
        msgNumber = -2;
    }

    if (error.response.data.err) {
      Notify(error.response.data.err.msg, error.response.data.err.type, 8000);
      return Promise.resolve(<IHttpBaseResponse>{
        isOk: false,
        data: null,
        message: error.response.data.err.msg,
        messageNumber: msgNumber,
        state: stateStr,
      });
    } else {
      Notify(error.response.statusText, "error", 4000);
      return Promise.resolve(<IHttpBaseResponse>{
        isOk: false,
        data: null,
        message: error.response.statusText,
        messageNumber: msgNumber,
        state: stateStr,
      });
    }
  },
};
