<template>
  <div class="dro-layout-empty-container">
    <slot></slot>
  </div>
</template>

<style>
.dro-layout-empty-container {
  height: 100%;
  display: flex;
}
</style>
