import { createRouter, createMemoryHistory } from "vue-router";
import layoutSimple from "./layouts/dro-layout-simple";
import layoutEmpty from "./layouts/dro-layout-empty.vue";

function loadView(view) {
  return () => import(`./views/${view}.vue`);
}

const router = new createRouter({
  routes: [
    {
      path: "/home",
      meta: {
        layout: layoutSimple,
        title: "Diresto",
      },
      component: loadView("dro-app-home"),
    },
    {
      path: "/cenik",
      meta: {
        layout: layoutSimple,
        title: "Ceník",
      },
      component: loadView("dro-app-pricing"),
    },
    {
      path: "/app-resetpassword",
      meta: {
        layout: layoutSimple,
        title: "Resetovat heslo",
        description:
          "Zadejte email použitý při registraci a my vám odešleme email s odkazem na resetování hesla.",
      },
      component: loadView("dro-app-loginresetpwd"),
    },
    {
      path: "/app-loading",
      meta: {
        layout: layoutEmpty,
        title: "",
      },
      component: loadView("dro-app-loading"),
    },
    {
      path: "/app-final",
      meta: {
        layout: layoutSimple,
        title: "",
      },
      component: loadView("dro-app-final"),
    },
    {
      path: "/app-resetpwdprocess",
      meta: {
        layout: layoutSimple,
        title: "",
      },
      component: loadView("dro-app-resetpwdprocess"),
    },
    {
      path: "/",
      redirect: "/app-loading",
    },
    {
      path: "/:pathMatch(.*)*",
      redirect: "/app-final",
    },
  ],
  history: createMemoryHistory(),
  //history: createWebHistory(),
});

export default router;
