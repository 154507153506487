<template>
  <div id="root" :class="cssClasses">
    <component
      :is="$route.meta.layout"
      :title="title"
      :is-x-small="screen.getScreenSizeInfo.isXSmall"
      :is-small="screen.getScreenSizeInfo.isSmall"
      :is-medium="screen.getScreenSizeInfo.isMedium"
      :is-large="screen.getScreenSizeInfo.isLarge"
    >
      <div class="dro-content">
        <router-view></router-view>
      </div>
      <div class="dro-footer"></div>
    </component>
  </div>
</template>

<script setup>
import { GUID } from "./functions";
import { useStore } from "vuex";

import { sizes, subscribe, unsubscribe } from "./utils/media-query";
import csMessages from "devextreme/localization/messages/cs.json";
import { locale, loadMessages } from "devextreme/localization";
import {
  getCurrentInstance,
  reactive,
  onMounted,
  onBeforeUnmount,
  computed,
} from "vue";

import { GetElemFromWindowName, SetElemToWindowName } from "@/functions";

const store = useStore();
const vm = getCurrentInstance();

const title = vm.proxy.$appInfo.title;
const screen = reactive({ getScreenSizeInfo: {} });
//const appVersion = store.getters["getAppVersion"];
screen.getScreenSizeInfo = getScreenSizeInfo();

function screenSizeChanged() {
  screen.getScreenSizeInfo = getScreenSizeInfo();
}

function getScreenSizeInfo() {
  const screenSizes = sizes();

  return {
    isXSmall: screenSizes["screen-x-small"],
    isSmall: screenSizes["screen-small"],
    isMedium: screenSizes["screen-medium"],
    isLarge: screenSizes["screen-large"],
    cssClasses: Object.keys(screenSizes).filter((cl) => screenSizes[cl]),
  };
}

onMounted(() => {
  subscribe(screenSizeChanged);
});

onBeforeUnmount(() => {
  unsubscribe(screenSizeChanged);
});

const cssClasses = computed(() => {
  return ["app"].concat(screen.getScreenSizeInfo.cssClasses);
});

// created hook

loadMessages(csMessages);
locale(navigator.language);

let ssn = GetElemFromWindowName("NOPS-SLUID");
if (!ssn) {
  ssn = GUID();
  SetElemToWindowName("NOPS-SLUID", ssn);
}
store.state.ssnLocalUId = ssn;

if (!store.getters["getDeviceUId"]) {
  store.commit("setDeviceUId", GUID());
}
</script>

<style lang="scss">
html,
body {
  margin: 0px;
  min-height: 100%;
  height: 100%;
}

#root {
  height: 100%;
}

* {
  box-sizing: border-box;
}

.app {
  @import "@/styles/dro-styles-base.scss";
  /*background-color: darken($base-bg, 5);*/
  background-color: white;
  height: 100%;
  width: 100%;
  font-size: 15px;
}

.dx-overlay-shader {
  background-color: rgba(220, 220, 220, 0.6);
}

.drwm-form {
  height: 100%;
  max-height: 100%;
  // background-color: #fdfdfd;
  background-color: #fafafa;
  .dx-card {
    border: 1px;
    background-color: inherit;
  }
}

.drwm-form-detail-popup {
  min-width: 100%;
  min-height: 100%;
  .dx-overlay-content {
    .dx-popup-title {
      min-height: 35px;
      background-color: whitesmoke;
      .dx-toolbar-items-container {
        min-height: 35px;
        font-weight: 600;
      }
    }
    .dx-popup-bottom {
      background-color: whitesmoke;
    }
    .screen-x-small & {
      width: 100% !important;
      height: 100% !important;
    }
  }
}

.drwm-datagrid {
  -webkit-user-select: none;
  user-select: none;
  height: 100%;
  .dx-datagrid {
    background-color: inherit;
    .dx-datagrid-header-panel {
      .dx-toolbar {
        background-color: inherit;
      }
    }
    .dx-datagrid-headers {
      background-color: white;
    }
    .dx-datagrid-rowsview {
      background-color: white;
    }
  }
}

.dx-command-select {
  width: 35px !important;
  min-width: 35px !important;
}

#app {
  height: 100%;
}

.dro-footer {
  text-align: right;
  padding: 10px 0 0 0;
  font-size: 0.8em;
}
</style>
