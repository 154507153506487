<template>
  <div>
    <DxPopup
      v-model:visible="popupVisible"
      :drag-enabled="false"
      :hide-on-outside-click="true"
      :show-close-button="false"
      :show-title="true"
      :width="400"
      :height="280"
      container=".dx-viewport"
      title="Přihlášení"
      :showTitle="false"
    >
      <DxPosition at="top" my="center" collision="fit"
        ><DxBoundaryOffset :x="0" :y="50"
      /></DxPosition>
      <DroLogin @resetPasswordClick="resetPasswordClick" />
    </DxPopup>

    <header class="dro-header">
      <nav>
        <div class="dro-container">
          <div class="dro-navbar-header">
            <router-link to="/home" class="dro-navbar-header-logo-link"
              ><img src="@/assets/applogo.svg" style="height: 35px"
            /></router-link>
            <DxButton
              icon="menu"
              height="40px"
              width="40px"
              class="dro-navbar-header-menu-toggle"
              @click="toggleMenu"
            />
          </div>
          <div
            class="dro-navbar-header-wrapper"
            :class="openMenu ? 'dro-navbar-header-wrapper-open' : ''"
          >
            <div id="dro-navbar" class="dro-navbar-header-menu">
              <ul class="dro-navbar-header-link">
                <li v-for="menuLink in menuList" :key="menuLink.path">
                  <router-link
                    :to="menuLink.path"
                    :class="route.path === menuLink.path ? 'active' : ''"
                    >{{ menuLink.title }}</router-link
                  >
                </li>
              </ul>
            </div>
            <div class="dro-navbar-header-login">
              <DxButton
                text="Přihlášení"
                icon="user"
                height="40px"
                type="normal"
                stylingMode="outlined"
                class="dro-header-login-button"
                id="dro-login-button"
                @click="showLoginForm"
              ></DxButton>
            </div>
          </div>
        </div>
      </nav>
    </header>

    <dx-scroll-view height="100%" width="100%" class="dro-container">
      <slot></slot>
    </dx-scroll-view>
  </div>
</template>

<script setup>
import { watch, ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import DxButton from "devextreme-vue/button";
import DxScrollView from "devextreme-vue/scroll-view";
import { DxPopup, DxPosition, DxBoundaryOffset } from "devextreme-vue/popup";
import DroLogin from "@/components/dro-login.vue";

const route = useRoute();
const title = ref(route.meta.title);
const description = ref("");
const openMenu = ref(false);
const popupVisible = ref(false);

const menuList = ref([
  { title: "Domů", path: "/home" },
  { title: "Ceník", path: "/cenik" },
]);

watch(
  () => route.path,
  () => {
    title.value = route.meta.title;
    description.value = route.meta.description;
  }
);

onMounted(() => {
  let qs = new URLSearchParams(location.search);
  if (qs.has("login")) {
    showLoginForm();
  }
});

function resetPasswordClick() {
  popupVisible.value = false;
}

function toggleMenu() {
  openMenu.value = !openMenu.value;
}

function showLoginForm() {
  popupVisible.value = true;
}
</script>

<style lang="scss">
@import "@/styles/dro-styles.scss";

.nav-menu {
  background-color: white;
}
.nav-content {
  display: flex;
  justify-content: space-between;
  padding: 10px 30px;
  align-items: center;
}
.nav-items {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    padding: 0 10px;
  }
}
i {
  display: none;
}
// Mobile version - hidden hamburger menu
@media screen and (max-width: 768px) {
  .nav-menu {
    padding-top: 10px;
    position: absolute;
    width: 100%;
  }
  .open-menu {
    opacity: 1;
    height: 150px;
  }
  .closed-menu {
    opacity: 0;
    height: 0;
    padding: 0;
  }
  .nav-content {
    flex-direction: column;
    z-index: 100;
    position: relative;
    transition: all 0.2s ease-out;
  }
  .nav-items {
    flex-direction: column;
  }
  i {
    display: block;
    text-align: right;
    padding: 0 10px 10px 0;
  }
}

/*
.single-card {
  width: 100%;
  height: 100%;

  .dx-card {
    width: 330px;
    margin: auto auto;
    padding: 40px;
    flex-grow: 0;

    .screen-x-small & {
      width: 100%;
      height: 100%;
      border-radius: 0;
      box-shadow: none;
      margin: 0;
      border: 0;
      flex-grow: 1;
    }

    .header {
      margin-bottom: 10px;

      .imagetitle {
        line-height: 28px;
        font-weight: 500;
        font-size: 20px;
        padding-left: 15px;
        color: darkslategrey;
      }

      .title {
        color: $base-text-color;
        line-height: 28px;
        font-weight: 500;
        font-size: 20px;
        padding-top: 20px;
      }

      .description {
        color: rgba($base-text-color, alpha($base-text-color) * 0.7);
        line-height: 18px;
      }
    }
  }
    
}
  */
</style>
