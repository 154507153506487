<template>
  <DxTabPanel
    width="100%"
    item-template="tabPanelItem"
    :animation-enabled="true"
    :swipe-enabled="true"
    :data-source="tabDataSource"
    tabs-position="top"
    icon-position="start"
    styling-mode="primary"
    class="dro-login-tab"
  >
    <template #tabPanelItem="{ data }">
      <div class="dro-login-tab-item">
        <form
          class="dro-login-form"
          @submit.prevent="onSubmit"
          v-if="data.name === 'login'"
        >
          <DxForm :form-data="formData" :disabled="loading">
            <DxItem
              data-field="email"
              editor-type="dxTextBox"
              :editor-options="{
                stylingMode: 'filled',
                placeholder: 'Email',
                mode: 'email',
              }"
            >
              <DxRequiredRule message="Email je povinný" />
              <DxEmailRule message="Email není platný" />
              <DxLabel :visible="false" />
            </DxItem>
            <DxItem
              data-field="password"
              editor-type="dxTextBox"
              :editor-options="{
                stylingMode: 'filled',
                placeholder: 'Heslo',
                mode: 'password',
              }"
            >
              <DxRequiredRule message="Heslo je povinné" />
              <DxLabel :visible="false" />
            </DxItem>

            <DxButtonItem>
              <DxButtonOptions
                width="100%"
                height="32px"
                type="default"
                template="signInTemplate"
                :use-submit-behavior="true"
              >
              </DxButtonOptions>
            </DxButtonItem>
            <DxItem v-if="devServiceUrlEnabled">
              <template #default>
                <DxCheckBox v-model="useDevServer" text="Použít dev server" />
              </template>
            </DxItem>
            <DxItem>
              <template #default>
                <div class="dro-link">
                  <router-link
                    to="/app-resetpassword"
                    @click="resetPasswordClick"
                    >Zapomenuté heslo?</router-link
                  >
                </div>
              </template>
            </DxItem>
            <template #signInTemplate>
              <div>
                <span class="dx-button-text">
                  <DxLoadIndicator
                    v-if="loading"
                    width="24px"
                    height="24px"
                    :visible="true"
                  />
                  <span v-if="!loading">Přihlásit</span>
                </span>
              </div>
            </template>
          </DxForm>
        </form>
      </div>
    </template>
  </DxTabPanel>
</template>

<script lang="ts" setup>
import DxLoadIndicator from "devextreme-vue/load-indicator";
import DxForm, {
  DxItem,
  DxEmailRule,
  DxRequiredRule,
  DxLabel,
  DxButtonItem,
  DxButtonOptions,
} from "devextreme-vue/form";
import DxTabPanel from "devextreme-vue/tab-panel";
import DxCheckBox from "devextreme-vue/check-box";
import notify from "devextreme/ui/notify";

import { login } from "@/store/api/auth";
import { ILoginRequest } from "@/core/interface/ILoginRequest";

import { ref, reactive, computed, defineEmits } from "vue";
import store from "@/store";

import { SetElemToWindowName } from "@/functions";

const loading = ref(false);
const formData = reactive({
  email: "",
  password: "",
});

const tabDataSource = [
  {
    icon: "description",
    title: "Přihlášení",
    name: "login",
  },
  {
    icon: "description",
    title: "Registrace",
    name: "register",
  },
];

const useDevServer = ref(false);
if (
  store.getters["getUseDevCfgService"] &&
  store.getters["getUseDevCfgService"].toLowerCase() === "true"
)
  useDevServer.value = true;

const devServiceUrlEnabled = computed(() => {
  return store.getters["getDevCfgServiceUrl"] ? true : false;
});

const emit = defineEmits(["resetPasswordClick"]);

function resetPasswordClick() {
  emit("resetPasswordClick");
}

async function onSubmit() {
  loading.value = true;

  store.commit("setUseDevCfgService", useDevServer.value);

  let loginRequest: ILoginRequest = {
    userId: formData.email,
    pwd: formData.password,
  };
  const result = await login(loginRequest);

  loading.value = false;

  if (!result) {
    notify("Unknown error (empty login response)", "error", 5000);
  } else if (!result.isOk) {
    notify(result.message, "error", 5000);
  } else if (!result.data) {
    notify("No data in login response", "error", 5000);
  } else if (!result.data.accessToken) {
    notify("No accessToken in login response", "error", 5000);
  } else if (!result.data.redirectTo) {
    notify("No redirectTo in login response", "error", 5000);
  } else if (!result.data.urlBackend) {
    notify("No urlBackend in login response", "error", 5000);
  } else {
    //let urlRedirect = null;
    let urlBackend = null;
    let accessToken = null;

    try {
      urlBackend = new URL(result.data.urlBackend);
    } catch (_) {
      notify("Bad URL in urlBackend", "error", 5000);
      return;
    }

    accessToken = result.data.accessToken;
    if (accessToken.length < 40) {
      notify("Bad accessToken in login response", "error", 5000);
      return;
    }

    SetElemToWindowName("ACCESS-TOKEN", accessToken);
    SetElemToWindowName("URL-BACKEND", urlBackend.toString());

    // Redirect na WM
    if (process.env.NODE_ENV === "development") {
      window.location.href = "http://localhost:8082/";
    } else if (result.data && result.data.redirectTo) {
      window.location.href = result.data.redirectTo;
    }
  }
}
</script>

<style lang="scss">
@import "@/styles/dro-styles.scss";

.dro-login-form {
  .dro-link {
    text-align: center;
    font-size: 16px;
    font-style: normal;

    a {
      text-decoration: none;
    }
  }

  .dro-form-text {
    margin: 10px 0;
    color: rgba($base-text-color, alpha($base-text-color) * 0.9);
  }
}
.dro-login-tab {
  .dx-tab-text {
    font-size: 18px;
  }
}
.dro-login-tab-item {
  padding: 15px;
}
</style>
