import { IHttpBaseResponse } from "@/core/interface/IHttpBaseResponse";
import { ILoginRequest } from "@/core/interface/ILoginRequest";
import { IResetPasswordRequest } from "@/core/interface/IResetPasswordRequest";
import { IResetPasswordStoreRequest } from "@/core/interface/IResetPasswordStoreRequest";

import store from "@/store";
import Http from "@/store/modules/http";
import CryptoJS from "crypto-js";
import { AESEncrypt } from "@/functions";

export async function login(
  paramObj: ILoginRequest
): Promise<IHttpBaseResponse> {
  const loginRequestInner = {
    deviceUId: store.getters["getDeviceUId"],
    authId: paramObj.userId,
    appProductUId: store.getters["getAppProductUId"],
    appSsnUId: store.state.ssnLocalUId,
    appVersion: store.getters["getAppVersion"],
    secTS: Date.now(),
  };

  const loginRequest = {
    loginUser: paramObj.userId,
    loginContext: CryptoJS.AES.encrypt(
      JSON.stringify(loginRequestInner),
      paramObj.pwd
    ).toString(),
  };
  return await Http.post("/auth/login", JSON.stringify(loginRequest));
}

export async function resetPassword(
  paramObj: IResetPasswordRequest
): Promise<IHttpBaseResponse> {
  const resetPasswordRequest = {
    email: paramObj.email,
  };

  return await Http.post(
    "/auth/resetpwd",
    JSON.stringify(resetPasswordRequest)
  );
}

export async function resetPasswordStore(
  paramObj: IResetPasswordStoreRequest
): Promise<IHttpBaseResponse> {
  const resetPasswordStoreRequest = {
    Token: paramObj.appResetPwdToken,
    Context: AESEncrypt(
      JSON.stringify({
        token: paramObj.appResetPwdToken,
        pwd: paramObj.newPassword,
      }),
      paramObj.appResetPwdUId
    ).toString(),
  };

  return await Http.post(
    "/resetpassword/store",
    JSON.stringify(resetPasswordStoreRequest)
  );
}

export async function tokenVerify(token: string): Promise<IHttpBaseResponse> {
  return await Http.post(
    "/resetpassword/tokenverify",
    JSON.stringify({
      token: token,
    })
  );
}
