export default {
  namespaced: true,
  state: {},
  actions: {
    log(thisModule, payload) {
      // TYPES:
      // - error
      // - info
      // - warning
      // ...
      if (payload.type === "error") {
        console.error("ERROR: " + payload.message);
      }
    },
  },
};
