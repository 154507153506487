// import router from "@/router.js";
import { GUID } from "@/functions";

export default {
  namespaced: true,

  state: {
    //appVersion: require("@/../package.json").version,
    isLoading: false,

    deviceId: localStorage.getItem("deviceId"),
  },
  getters: {
    getDeviceId: (state) => {
      if (state.deviceId == null) {
        state.deviceId = localStorage.getItem("deviceId");
        if (state.deviceId == null) {
          state.deviceId = GUID();
          localStorage.setItem("deviceId", state.deviceId);
        }
      }
      return state.deviceId;
    },
  },
  mutations: {
    setUid(state, value) {
      state.uid = value;
      if (value) {
        sessionStorage.setItem("uid", value);
      } else {
        sessionStorage.removeItem("uid");
      }
    },
    setIsLoading(state, value) {
      state.isLoading = value === true ? true : false;
    },
  },
};
